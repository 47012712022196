<template>
  <div class="pricing-grid">
    <checkout-btn-modal
      @close="showModal = false"
      :planName="choosePlanName"
      :show="showModal"
    ></checkout-btn-modal>
    <div class="box mb-4">
      <div class="columns">
        <div class="column has-text-centered pt-1">
          <b-field
            class="is-inline-block has-text-left"
            label="See prices for:"
          >
            <b-select v-model="selectedTypes">
              <option :value="['beergame']">Beer Game</option>
              <option :value="['leangame']" disabled
                >Lean Game (coming soon!)</option
              >
              <option :value="['beergame', 'leangame']" disabled
                >Beer Game and Lean Game</option
              >
            </b-select>
          </b-field>
        </div>
        <div class="column has-text-centered pt-1">
          <b-field class="is-inline-block has-text-left" label="Plan Duration:">
            <b-radio-button
              v-model="switchYear"
              :native-value="0"
              type="is-info"
            >
              <b-tooltip
                type="is-dark"
                position="is-bottom"
                size="is-small"
                label="Get access during one month to prepare and host your event"
                multilined
              >
                <span>One-time Event</span>
              </b-tooltip>
            </b-radio-button>

            <b-radio-button
              v-model="switchYear"
              :native-value="1"
              type="is-info"
            >
              <b-tooltip
                type="is-dark"
                position="is-bottom"
                size="is-small"
                label="Host unlimited sessions for one year"
                multilined
              >
                <span>Annual Plan</span>
              </b-tooltip>
            </b-radio-button>
          </b-field>
        </div>
        <div class="column has-text-centered pt-1">
          <b-field
            class="is-inline-block has-text-left"
            label="Profile type: Educational"
            v-if="currentUser.profileType !== 'business'"
          >
            <p class="control is-size-7">
              If you are using the app as a professional, please
              <a
                class="has-text-primary has-text-weight-bold"
                @click="toggleProfileType"
                >update your profile to Business now</a
              >
            </p>
          </b-field>
          <b-field
            class="is-inline-block has-text-left"
            label="Profile type: Business"
            v-if="currentUser.profileType === 'business'"
          >
            <p class="control is-size-7">
              If you are using the app at school or university,
              <a
                class="has-text-primary has-text-weight-bold"
                @click="toggleProfileType"
                >update your profile now</a
              >
            </p>
          </b-field>
        </div>
      </div>
    </div>
    <div class="has-text-centered" v-if="accountStatus.message">
      <b class="has-text-danger">{{ accountStatus.message }}</b>
    </div>
    <div class="columns is-centered mt-2">
      <div class="pricing-table column is-three-quarters mt-0">
        <div class="pricing-plan" v-if="!switchYear">
          <div class="plan-header">
            <div class="is-size-5">
              {{ switchYear ? "Annual" : "Conference" }} Basic
              {{ chooseType === "edu" ? "for Education" : "" }}
            </div>
            <div class="subtitle is-size-6">For small groups</div>
          </div>
          <div class="plan-price" v-if="quoteData">
            <span class="plan-price-amount is-size-4"
              ><span class="plan-price-currency">{{ quoteData.symbol }}</span>
              {{ displayMonthlyBasic
              }}<span class="is-size-6" v-if="switchYear">/month</span>
              <br />
            </span>
            <span
              class="is-size-7 has-text-grey"
              v-if="quoteData.currency === 'eur'"
            >
              excl. tax</span
            >
          </div>
          <div class="plan-items">
            <div class="plan-item is-size-7">
              <b>Up to 20 Players</b>
            </div>
            <div class="plan-item">
              <div class="has-text-primary">Custom Layout</div>
              <ul class="is-unstyled is-size-7">
                <li>
                  2 to 8 stages
                </li>
              </ul>
            </div>
          </div>
          <div class="plan-footer">
            <b-button
              v-if="accountStatus.canPurchase"
              class="btn-modal-purchase btn-modal-basicplan"
              type="is-light"
              @click="clickPlan('basic')"
              >Purchase</b-button
            >
          </div>
        </div>
        <div class="pricing-plan is-primary is-active">
          <div class="plan-header is-size-4">
            <div class="is-size-5">
              {{ switchYear ? "Annual" : "Conference" }} Premium
              {{ chooseType === "edu" ? "for Education" : "" }}
            </div>
            <div class="subtitle is-size-6">Organize complete sessions</div>
          </div>
          <div class="plan-price" v-if="quoteData">
            <span class="plan-price-amount is-size-4"
              ><span class="plan-price-currency">{{ quoteData.symbol }}</span>
              {{ displayMonthlyPremium
              }}<span class="is-size-6" v-if="switchYear">/month</span>
              <br />
            </span>
            <span class="is-size-7 has-text-grey">
              <span v-if="switchYear"
                >{{
                  (displayMonthlyPremium * 12) | currency(quoteData.symbol)
                }}
                billed annually,</span
              >
              <span v-if="quoteData.currency === 'eur'">excl. tax</span></span
            >
          </div>
          <div class="plan-items">
            <div class="plan-item is-size-7">
              <b>Unlimited Players</b>
            </div>
            <div class="plan-item">
              <div class="has-text-primary">Custom Layout</div>
              <ul class="is-unstyled is-size-7">
                <li>
                  2 to 8 stages
                </li>
              </ul>
            </div>
            <div class="plan-item">
              <ul class="is-unstyled is-size-7">
                <li
                  v-for="extra in plans.features['premium'].extra"
                  :key="extra"
                >
                  <b-icon
                    class="is-pulled-left"
                    size="is-small"
                    icon="check"
                  ></b-icon>
                  {{ extra }}
                </li>
              </ul>
            </div>
          </div>
          <div class="plan-footer">
            <b-button
              v-if="accountStatus.canPurchase"
              class="btn-modal-purchase btn-modal-premiumplan"
              @click="clickPlan('premium')"
              >Purchase</b-button
            >
          </div>
        </div>
        <div class="pricing-plan" v-if="switchYear">
          <div class="plan-header is-size-4">
            <div class="is-size-5">
              {{ chooseType === "edu" ? "Campus" : "Enterprise" }}
            </div>
            <div class="subtitle is-size-6">Help your teams train better</div>
          </div>
          <div class="plan-price">
            <span class="plan-price-amount is-size-4"
              >Contact us
              <br />
            </span>
            <span class="is-size-7 has-text-grey">hello@beergameapp.com</span>
          </div>
          <div class="plan-items">
            <div class="plan-item">
              <ul class="is-unstyled is-size-7">
                <li class="has-text-weight-bold">
                  All Premium features, plus :
                </li>
                <li
                  v-for="param in plans.features['enterprise'].params"
                  :key="param"
                >
                  <b-icon
                    class="is-pulled-left"
                    size="is-small"
                    icon="check"
                  ></b-icon>
                  {{ param }}
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import plans from "@/../functions/plans.json";
import CheckoutBtnModal from "@/components/modal/CheckoutBtnModal.vue";

export default {
  name: "premiumTab",
  computed: {
    ...mapGetters([
      "authUser",
      "gameTypes",
      "teamAccount",
      "quoteData",
      "currentUser"
    ]),
    chooseType() {
      return this.currentUser.profileType === "business" ? "std" : "edu";
    },
    accountStatus() {
      let obj = {
        canPurchase: this.authUser && this.authUser.email,
        message: null
      };
      if (
        this.teamAccount &&
        (!this.teamAccount.members ||
          !this.teamAccount.members[this.authUser.uid] ||
          this.teamAccount.members[this.authUser.uid].role !== "admin")
      ) {
        obj = {
          canPurchase: false,
          message:
            "You don't have the rights to change your account plans. Please contact the admin of your team."
        };
      }
      Object.values(this.selectedTypes).forEach(gt => {
        if (
          this.teamAccount &&
          this.teamAccount.plans &&
          this.teamAccount.plans[gt] &&
          this.teamAccount.plans[gt].active
        ) {
          obj = {
            canPurchase: false,
            message: `Your account already has an active license for ${
              this.selectedTypes.length > 1
                ? "one of the selected games"
                : "this game"
            }`
          };
        }
      });
      return obj;
    },
    displayMonthlyBasic() {
      let findPlan = this.plans.list.find(
        plan =>
          plan.plan == "basic" &&
          plan.type == this.chooseType &&
          plan.duration == (this.switchYear ? 12 : 1)
      );
      return findPlan[this.quoteData.currency] / findPlan.duration;
    },
    displayMonthlyPremium() {
      let findPlan = plans.list.find(
        plan =>
          plan.plan == "premium" &&
          plan.type == this.chooseType &&
          plan.duration == (this.switchYear ? 12 : 1)
      );
      return findPlan[this.quoteData.currency] / findPlan.duration;
    }
  },
  created() {
    if (!this.quoteData) {
      this.$store.dispatch("updateQuoteData");
    }
  },
  components: { CheckoutBtnModal },
  methods: {
    clickPlan(plan) {
      this.$store.dispatch("updateQuoteData");
      this.choosePlanName = plans.list.find(
        pp =>
          pp.plan == plan &&
          pp.type == this.chooseType &&
          pp.duration == (this.switchYear ? 12 : 1)
      )["name"];
      this.showModal = true;
    },
    toggleProfileType() {
      let newType =
        this.currentUser.profileType !== "business" ? "business" : "education";
      this.$buefy.dialog.confirm({
        message: `Update your profile type to <b>${newType}</b> ?${
          newType === "education"
            ? "<br>Your account must use your university email."
            : ""
        }`,
        cancelText: "Cancel",
        size: "is-medium",
        confirmText: "Update",
        onConfirm: () => {
          this.$store
            .dispatch("updateUserProfile", {
              uid: this.currentUser.uid,
              profileType: newType
            })
            .then(() => {
              this.$buefy.toast.open("Profile updated");
            });
        }
      });
    }
  },
  data() {
    return {
      selectedTypes: ["beergame"],
      plans: plans,
      switchYear: 0,
      choosePlanName: null,
      checkEdu: false,
      showModal: false
    };
  }
};
</script>
<style lang="scss">
.pricing-grid {
  .b-tabs .tab-content {
    text-align: center;
    padding-bottom: 0;
  }
  .pricing-table {
    margin-top: 1em;
    margin-bottom: 1em;
    .pricing-plan {
      flex-basis: 4em;
      &.pricing-smaller {
        flex-basis: 0em;
      }
      @media (max-width: 767px) {
        width: 100%;
      }
      .plan-header {
        padding: 0.5em;
        padding-bottom: 0.3em;
      }
      .plan-price {
        padding-top: 0;
      }
      .is-divider-vertical {
        padding: 0.5em;
        &::after {
          background-color: white;
        }
      }
    }
    .plan-item {
      padding: 0.45em;
      .subtitle {
        margin-bottom: 0.5em;
      }
    }
    ul.is-unstyled {
      list-style: none;
      margin: 0;
      padding: 0;
    }
  }
}
</style>
