<template>
  <div class="subscription-tab pb-5">
    <section class="section" v-if="currentUser">
      <h1 class="title is-medium mb-0">
        Subscription
      </h1>
      <div class="section mt-0 pt-0">
        <b-tabs size="is-medium" v-model="subTab">
          <b-tab-item label="Overview">
            <div class="columns is-centered">
              <div class="column is-7">
                <div
                  v-for="pt in listPlans"
                  :key="`pt-${pt.type}`"
                  class="mb-5 is-relative"
                >
                  <div
                    class="box gametype-rounded-block"
                    :class="{
                      [`${pt.type}-class`]: true,
                      'is-active': pt.active
                    }"
                  >
                    <div class="media">
                      <figure class="media-left mr-1">
                        <p class="image is-48x48">
                          <img :src="pt.logo" />
                        </p>
                      </figure>
                      <div class="media-content ml-4 has-text-left">
                        <b class="is-size-4">{{ pt.name }}</b>
                        <div v-if="!pt.active">
                          No active plan
                        </div>
                        <div v-if="pt.active">
                          <div class="columns mb-0 is-mobile">
                            <div class="column pb-1">
                              <b>Plan:</b> {{ pt.planName }}
                            </div>
                            <div
                              class="column is-narrow pb-1 has-text-right"
                              v-if="pt.until && pt.until.toDate()"
                            >
                              <b>Until:</b>
                              {{ pt.until.toDate().toLocaleDateString() }}
                            </div>
                          </div>
                          <p
                            class="is-size-7 has-text-info"
                            v-if="pt.hasLicense"
                          >
                            <b-icon icon="check" size="is-small"></b-icon> You
                            have an active license for this game
                          </p>
                          <p
                            class="is-size-7 has-text-danger"
                            v-if="!pt.hasLicense"
                          >
                            <b-icon icon="close" size="is-small"></b-icon> No
                            license is attributed to you for this game
                          </p>
                          <div class="is-divider my-2"></div>
                          <div>
                            <div class="columns mb-0 is-mobile">
                              <div class="column pb-0">
                                <b>Instructor licenses:</b>
                              </div>
                              <div class="column has-text-right pb-0 is-size-7">
                                <router-link
                                  to="/dashboard/team"
                                  class="has-text-weight-bold"
                                >
                                  View team →
                                </router-link>
                              </div>
                            </div>
                            <b-progress
                              class="my-1"
                              :type="pt.classColor ? pt.classColor : 'is-info'"
                              :value="
                                (100 * pt.countInstructors) /
                                  Math.max(1, pt.totLicenses || 0)
                              "
                              size="is-small is-very-small"
                            ></b-progress>
                            <div class="columns is-size-7 is-mobile">
                              <div class="column">
                                Assigned:
                                <b>{{ pt.countInstructors || 0 }}</b>
                              </div>
                              <div class="column has-text-right">
                                Tot:
                                <b>{{ Math.max(1, pt.totLicenses || 0) }}</b>
                              </div>
                            </div>
                          </div>
                          <div class="is-divider my-2"></div>

                          <div class="columns mb-0 is-mobile">
                            <div class="column pb-0">
                              <b>Players: </b>
                              <span v-if="pt.unlimitedPlayers">Unlimited</span>
                            </div>
                            <div
                              class="column has-text-right pb-0 is-size-7"
                              v-if="
                                pt.players &&
                                  pt.players.length &&
                                  !pt.unlimitedPlayers
                              "
                            >
                              <b-tooltip
                                type="is-dark"
                                size="is-medium"
                                multilined
                                label="To disconnect players from specific games, select them in the Games menu"
                              >
                                <a
                                  @click="discoAllPlayers"
                                  class="has-text-danger has-text-weight-bold"
                                  v-if="pt.players.length < 0.6 * pt.maxPlayers"
                                  >Disconnect all players</a
                                >
                                <b-button
                                  @click="discoAllPlayers"
                                  class="mb-1"
                                  size="is-small"
                                  type="is-danger"
                                  v-if="
                                    pt.players.length >= 0.6 * pt.maxPlayers
                                  "
                                  rounded
                                  icon-left="account-multiple-remove"
                                  >Disconnect all players</b-button
                                >
                              </b-tooltip>
                            </div>
                          </div>
                          <div v-if="pt.maxPlayers && !pt.unlimitedPlayers">
                            <b-progress
                              class="my-1"
                              :type="pt.classColor ? pt.classColor : 'is-info'"
                              :value="pt.pourcPlayers"
                              size="is-small is-very-small"
                            ></b-progress>
                            <div class="columns is-size-7 is-mobile">
                              <div class="column">
                                <b-tooltip
                                  multilined
                                  style="cursor: help;"
                                  type="is-dark"
                                  label="Number of players who joined a game since your plan started"
                                >
                                  Connected:
                                  <b>{{
                                    pt.players ? pt.players.length : 0
                                  }}</b>

                                  <b-icon
                                    size="is-small"
                                    icon="help-circle-outline"
                                  ></b-icon>
                                </b-tooltip>
                              </div>
                              <div class="column has-text-right">
                                Max: <b>{{ pt.maxPlayers || 0 }}</b>
                              </div>
                            </div>
                          </div>
                          <div class="buttons is-right mt-2"></div>
                        </div>
                      </div>
                      <div class="media-right">
                        <b-icon
                          v-if="pt.active"
                          :type="pt.classColor"
                          icon="check-circle"
                        ></b-icon>
                      </div>
                    </div>
                  </div>
                  <div
                    v-if="!pt.active"
                    style="position: absolute; top: 30px; right: 30px;"
                  >
                    <a
                      v-if="pt.type === 'beergame'"
                      @click="linkPurchase(pt.type)"
                      class="has-text-info has-text-weight-bold"
                      >Purchase</a
                    >
                    <em v-if="pt.type !== 'beergame'">Coming soon</em>
                  </div>
                </div>
              </div>
              <!-- <div class="column is-5">
                <div class="box">
                  <h4 class="title is-6">Billing Address:</h4>
                </div>
              </div> -->
            </div>
          </b-tab-item>
          <b-tab-item label="All Plans">
            <pricing-grid ref="pricinggrid"></pricing-grid>
            <b-message class="mt-5" type="is-info">
              Learn more about the app, the Pricing plans and read the
              <b>
                Frequently Asked Questions
              </b>
              here : <br /><a
                class="has-text-primary has-text-weight-bold"
                target="_blank"
                href="https://beergameapp.com/pricing/"
                >https://beergameapp.com/pricing/</a
              >
            </b-message>
          </b-tab-item>
        </b-tabs>
      </div>
    </section>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import PricingGrid from "@/components/PricingGrid.vue";

export default {
  name: "subscriptionTab",
  components: { PricingGrid },
  computed: {
    ...mapGetters([
      "currentUser",
      "error",
      "showError",
      "teamAccount",
      "gameTypes",
      "availGames",
      "concatGamesList"
    ]),
    listPlans() {
      return Object.values(this.gameTypes).map(gt => {
        return {
          ...gt,
          ...(this.teamAccount &&
            this.teamAccount.plans &&
            this.teamAccount.plans[gt.type] &&
            this.teamAccount.plans[gt.type].active && {
              ...this.teamAccount.plans[gt.type],
              pourcPlayers:
                this.teamAccount.plans[gt.type].unlimitedPlayers ||
                !this.teamAccount.plans[gt.type].maxPlayers ||
                !this.teamAccount.plans[gt.type].players
                  ? 0
                  : Math.ceil(
                      (100 * this.teamAccount.plans[gt.type].players.length) /
                        this.teamAccount.plans[gt.type].maxPlayers
                    ),
              countInstructors: Object.values(this.teamAccount.members).filter(
                mb => mb.games && mb.games.includes(gt.type)
              ).length,
              hasLicense:
                this.teamAccount.members[this.currentUser.uid].games &&
                this.teamAccount.members[this.currentUser.uid].games.includes(
                  gt.type
                )
            })
        };
      });
    }
  },
  mounted() {
    if (!this.teamAccount) {
      this.subTab = 1;
    }
  },
  methods: {
    closeError() {
      this.$store.commit("clearError");
    },
    discoAllPlayers() {
      this.$store.dispatch(
        "disconnectPlayers",
        [...this.concatGamesList].filter(gg => {
          if (
            Object.values(gg.facilities).some(fac => {
              return fac.user;
            })
          ) {
            return true;
          }
        })
      );
    },
    linkPurchase(gt) {
      if (this.$refs.pricinggrid) {
        this.$refs.pricinggrid.selectedTypes = [gt];
      }
      this.subTab = 1;
    }
  },
  data() {
    return {
      subTab: 0,
      selectPlan: null,
      selectCurrency: { symbol: "€", key: "eur" },
      currencies: [{ symbol: "€", key: "eur" }]
    };
  }
};
</script>
<style lang="scss">
.subscription-tab {
  progress {
    background-color: white !important;
  }
  progress::-moz-progress-bar {
    background-color: white !important;
  }
  progress::-webkit-progress-bar {
    background-color: white !important;
  }
}
</style>
