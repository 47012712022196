<template>
  <b-modal
    class="checkout-modal"
    :active="show"
    v-if="selectedPlan"
    has-modal-card
    @close="$emit('close')"
  >
    <div class="modal-card">
      <header class="modal-card-head">
        <span class="modal-card-title has-text-centered"> Purchase </span>
      </header>
      <section class="modal-card-body section">
        <div class="notification is-light">
          <div class="columns">
            <div class="column is-size-6" v-if="currentUser">
              <b>Plan :</b>
              {{ selectedPlan.type === "edu" ? "Educational" : "" }}
              {{ selectedPlan.plan | capitalize }}
              <br />
              <b>Duration :</b> {{ selectedPlan.duration }} month{{
                selectedPlan.duration > 1 ? "s" : ""
              }}
              <em class="is-size-7 has-text-primary">+ 7 days trial</em>
              <br />
              <b>Account :</b> {{ currentUser.email }}
            </div>
            <div class="is-divider-vertical"></div>
            <div class="column is-size-6" v-if="quoteData">
              <p class="is-marginless">
                <span
                  >Price
                  {{ selectedPlan.duration > 1 ? "(annual billing)" : "" }}
                  :</span
                >
                <span class="is-pulled-right">{{
                  baseCost | currency(quoteData.symbol, 2)
                }}</span>
              </p>
              <p class="is-marginless">
                <span>VAT {{ quoteData.rate }}% :</span>
                <span class="is-pulled-right">{{
                  vatCost | currency(quoteData.symbol, 2)
                }}</span>
              </p>
              <p class="is-marginless has-text-weight-bold">
                <span>Total due :</span>
                <span class="is-pulled-right">{{
                  totalCost | currency(quoteData.symbol, 2)
                }}</span>
              </p>
            </div>
          </div>
          <div class="columns">
            <div class="column is-size-7">
              <p>
                This will give you access to the
                <b>{{ selectedPlan.plan | capitalize }}</b> functionalities
                until <b>{{ finishDate }}</b
                >. <br />If you wish to cancel within 7 days and you have not
                successfully used The Beergame App at a live event, you will
                receive a full refund.
              </p>
            </div>
          </div>
        </div>
        <div class="has-text-centered">
          <b-button
            type="is-primary"
            @click="clickCheckout"
            class="btn-checkout-pay"
            >Pay using credit card</b-button
          >
        </div>

        <!-- <div class="has-text-centered" v-if="selectedPlan.plan === 'premium'">
          <div class="is-divider" data-content="OR"></div>
          <b-button
            v-if="!invoiceOpen"
            type="is-warning"
            aria-controls="invoiceInfo"
            @click="invoiceOpen = true"
            >Pay on Invoice (Bank Transfer)</b-button
          >
          <em class="is-size-7"
            ><br />Only available for the Premium subscriptions</em
          >
        </div>
        <b-collapse
          aria-id="invoiceInfo"
          animation="slide"
          :open="invoiceOpen"
          v-if="selectedPlan.plan === 'premium'"
        >
          <div class="mt-1 mb-0 columns is-centered">
            <b-field label="Full Billing Address" class="column is-four-fifths">
              <template slot="label">
                Full Billing Address
                <br /><span class="has-text-weight-normal is-size-7"
                  >Please include your Company name and PO number if
                  applicable.<br />If you are a business located in the EU add
                  your VAT number.</span
                >
              </template>
              <b-input
                type="textarea"
                rows="3"
                placeholder="Hogwarts School of Wizardry, High Lands, Scotland - VAT GB10000000000"
              >
              </b-input>
            </b-field>
          </div>
          <div class="buttons is-centered">
            <b-button
              icon-left="close"
              type="is-light"
              aria-controls="invoiceInfo"
              @click="invoiceOpen = false"
              >Close</b-button
            >
            <b-button type="is-warning" @click="askForInvoice"
              >Ask for an Invoice</b-button
            >
          </div>
        </b-collapse> -->
      </section>
      <footer class="modal-card-foot"></footer>
    </div>
  </b-modal>
</template>

<script>
import { mapGetters } from "vuex";
import plans from "@/../functions/plans.json";

export default {
  name: "CheckoutModal",
  props: {
    show: Boolean,
    planName: String
  },
  computed: {
    ...mapGetters(["currentUser", "quoteData"]),
    selectedPlan() {
      return this.plans.list.find(pp => {
        return pp.name == this.planName;
      });
    },
    baseCost() {
      return this.selectedPlan[this.quoteData.currency];
    },
    vatCost() {
      return (this.baseCost * this.quoteData.rate) / 100;
    },
    totalCost() {
      return this.baseCost + this.vatCost;
    },
    finishDate() {
      let newDate = new Date();
      newDate.setMonth(newDate.getMonth() + this.selectedPlan.duration);
      newDate.setDate(newDate.getDate() + 7);
      return newDate.toLocaleDateString("en-EN", {
        year: "numeric",
        month: "long",
        day: "numeric"
      });
    }
  },
  methods: {
    askForInvoice() {
      console.log("asked");
    },
    clickCheckout() {
      this.$store.dispatch("startCheckoutSession", {
        ...this.selectedPlan,
        email: this.currentUser.email,
        vatrate: this.quoteData.rate,
        currency: this.quoteData.currency
      });
    }
  },
  data() {
    return {
      plans: plans,
      invoiceOpen: false
    };
  }
};
</script>

<style lang="scss">
.checkout-modal {
  display: inline-block;
  .card-element-wrapper {
    #card-element {
      padding: 1em;
      border: 1px solid hsl(0, 0, 80%);
      border-radius: 4px;
    }
  }
}
</style>
